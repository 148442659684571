import React, { useEffect, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import logo from "../../assets/img/logo.png"
import { services } from './services';
import { useUser } from '../../context/UserContext';
import { getMessaging, onMessage } from 'firebase/messaging';
import { app, messaging } from '../../firebase.config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NotificationPopup from './NotificationPopup';
import { useNotification } from '../../context/NotificationsContext';

const NavBar: React.FC = () => {
  const { user, setUser, loading } = useUser();
  console.log(user, "Use this user pascal ..........................");
  const [isNavCollapsed, setIsNavCollapsed] = useState<boolean>(true);
  const { hasNotification, setHasNotification } = useNotification();

  const [notification, setNotification] = useState<null | {
    title: string;
    body: string;
    image: string;
  }>(null);

  const location = useLocation();
  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  useEffect(() => {
    const messaging = getMessaging(app);

    const unsubscribe = onMessage(messaging, (payload) => {
      setHasNotification(true);
      const { title, body, image } = payload.notification || {};

      setNotification({
        title: title || 'Test Notification',
        body: body || 'This is a test notification for user O1LKbtmgeFgr21guJucpbEkqMOs1',
        image: image || 'https://example.com/notification-image.png',
      });

      // Hide it after 5 seconds
      setTimeout(() => setNotification(null), 5000);
    });

    return () => unsubscribe();
  }, []);

  return (
    <div className="container-fluidh nav-bar bg-transparent">

      <nav className="navbar navbar-expand-lg bg-white navbar-light py-0 px-4">
        <Link to="/" className="navbar-brand d-flex align-items-center text-center">
          <div className="icon-logo p-1 me-2">
            <img
              className="img-fluid"
              src={logo}
              alt="Icon"
              style={{ width: 50, height: 50 }}
            />
          </div>
          <h1 className="m-0 text-primary g-font-size-22--xs">iGiTREE</h1>
        </Link>

        <button
          type="button"
          className="navbar-toggler"
          data-bs-toggle="collapse"
          onClick={handleNavCollapse}
          aria-expanded={!isNavCollapsed}
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarCollapse">
          <div className="navbar-nav ms-auto">
            <NavLink to="/" className={({ isActive }) => `nav-item nav-link ${isActive ? 'active' : ''}`}>
              Home
            </NavLink>
            <NavLink to="/about" className={({ isActive }) => `nav-item nav-link ${isActive ? 'active' : ''}`}>
              About
            </NavLink>

            {/* Services Dropdown */}
            <div className="nav-item dropdown">
              <a
                href="#"
                className={`nav-link dropdown-toggle ${location.pathname.includes('service') ? 'active' : ''}`}
                data-bs-toggle="dropdown"
              >
                Services
              </a>
              <div className="dropdown-menu rounded-0 m-0">
                <Link
                  to="/ai-driven-family-discovery"
                  className="dropdown-item"
                >
                  AI Driven Family Discovery
                </Link>

                <Link
                  to="/dna-testing-and-matching"
                  className="dropdown-item"
                >
                  DNA Testing & Matching
                </Link>

                <Link
                  to="/family-tree-build"
                  className="dropdown-item"
                >
                  Family Tree Building
                </Link>

                <Link
                  to="/igitree-connect"
                  className="dropdown-item"
                >
                  iGiTREE Connect
                </Link>

              </div>
            </div>

            <NavLink to="/user-stories" className={({ isActive }) => `nav-item nav-link ${isActive ? 'active' : ''}`}>
              Stories
            </NavLink>

            <NavLink to="/contacts" className={({ isActive }) => `nav-item nav-link ${isActive ? 'active' : ''}`}>
              Contact
            </NavLink>
            {user && user.subscriptionStatus !== "active" ? (
            <div className='nav-item nav-link' style={{
              height: '50% '
            }} >
              <NavLink style={{
                padding: ".5rem", borderRadius: ".5rem"
              }} to="/upgrade" className={({ isActive }) => ` ${isActive ? 'active' : ''} text-bg-secondary`}>
                Upgrade to igitree + 
              </NavLink>
            </div>
            ): ''}

          </div>

          {user ? (
            <Link to="/dashboard" className="btn btn-primary align-items-center btn-flat">
              <i className="fa-sharp fa-light fa-user me-2"></i>
              {user?.firstName}
            </Link>
          ) : (
            <>
              <Link to="/login" className="btn btn-outline-primary align-items-center btn-flat me-2">
                SIGN IN
              </Link>
              <Link to="/register" className="btn btn-primary px-3 align-items-center btn-flat">
                CREATE ACCOUNT
              </Link>
            </>
          )}
        </div>

      </nav>
      {notification && <NotificationPopup
        title={notification.title}
        body={notification.body}
        image={notification.image}
        onClose={() => setNotification(null)}
      />
      }
    </div>
  );
};

export default NavBar;


