import React, { useState, useEffect } from "react";
import axios from "axios";
import logo from "../../assets/img/logo.png";
import { useNavigate } from "react-router-dom";
import API from "../../api/api";

interface RegisterForm {
  firstName: string;
  lastName: string;
  gender: string;
  phoneNumber: string;
  email: string;
  password: string;
  imageUrl: string;
  dateOfBirth: string;
  city: string;
  confirmPassword: string;
}

interface FormErrors {
  firstName?: string;
  lastName?: string;
  gender?: string;
  phoneNumber?: string;
  email?: string;
  password?: string;
  confirmPassword?: string;
  dateOfBirth?: string;
  city?: string;
}

const Register: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<RegisterForm>({
    firstName: "",
    lastName: "",
    gender: "",
    phoneNumber: "",
    email: "",
    password: "",
    imageUrl: "",
    dateOfBirth: "",
    city: "",
    confirmPassword: ""
  });

  const [message, setMessage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [fieldErrors, setFieldErrors] = useState<FormErrors>({});
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  const [inputNames, setInputNames] = useState({
    email: "email",
    password: "password",
    confirmPassword: "confirmPassword"
  });

  useEffect(() => {
    // Generate random names for inputs on component mount
    const randomString = () => Math.random().toString(36).substring(7);
    setInputNames({
      email: `email_${randomString()}`,
      password: `password_${randomString()}`,
      confirmPassword: `confirmPassword_${randomString()}`
    });
  }, []);

  // Validate form fields as user types
  useEffect(() => {
    const validateForm = () => {
      const errors: FormErrors = {};
      let valid = true;

      // First name validation
      if (formData.firstName.trim() && formData.firstName.length < 2) {
        errors.firstName = "First name must be at least 2 characters";
        valid = false;
      }

      // Last name validation
      if (formData.lastName.trim() && formData.lastName.length < 2) {
        errors.lastName = "Last name must be at least 2 characters";
        valid = false;
      }

      // Email validation
      if (formData.email.trim() && !validateEmail(formData.email)) {
        errors.email = "Please enter a valid email address";
        valid = false;
      }

      // Phone validation that requires international format
      if (formData.phoneNumber.trim()) {
        // Requires the "+" followed by country code and remaining digits
        // Total digits should be between 8-15 (including country code)
        const internationalPhoneRegex = /^\+[1-9]\d{1,3}[- ]?\d{3,12}$/;

        if (!internationalPhoneRegex.test(formData.phoneNumber)) {
          errors.phoneNumber =
            "Please enter phone number in international format[E.164 format] (e.g., +250787795163)";
          valid = false;
        }
      }
      // Password validation
      if (formData.password) {
        if (!isPasswordStrong(formData.password)) {
          errors.password =
            "Password must be at least 8 characters with a letter, number, and special character";
          valid = false;
        }
      }

      // Confirm password
      if (
        formData.confirmPassword &&
        formData.password !== formData.confirmPassword
      ) {
        errors.confirmPassword = "Passwords do not match";
        valid = false;
      }

      // Check if all required fields are filled
      const requiredFields = [
        "firstName",
        "lastName",
        "email",
        "password",
        "confirmPassword",
        "phoneNumber"
      ];
      const allFieldsFilled = requiredFields.every(
        (field) => formData[field as keyof RegisterForm].trim() !== ""
      );

      setFieldErrors(errors);
      setIsFormValid(valid && allFieldsFilled);
    };

    validateForm();
  }, [formData]);

  const preventAutofill = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.setAttribute("autocomplete", "new-password");
    e.target.setAttribute("readonly", "readonly");
    setTimeout(() => {
      e.target.removeAttribute("readonly");
    }, 100);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    // Map the random name back to the original name
    const originalName =
      Object.keys(inputNames).find(
        (key) => inputNames[key as keyof typeof inputNames] === name
      ) || name;

    setFormData((prev) => ({ ...prev, [originalName]: value }));

    // Clear global error on any input change
    if (error) {
      setError(null);
    }
  };

  // Email validation
  const validateEmail = (email: string) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(email);
  };

  // Password strength validation
  const isPasswordStrong = (password: string) => {
    const regex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
    return regex.test(password);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!isFormValid) {
      return; // Don't proceed if form is invalid
    }

    setLoading(true);
    setMessage("Creating your account...");
    setError(null);

    try {
      setMessage("Setting up your profile...");
      const response = await API.post(
        `${process.env.REACT_APP_BASE_URL}/auth/signup`,
        formData
      );
      if (response.data.status === 201) {
        setMessage(
          "🎉Registration successful! Please check your email to activate your account and start your journey with iGiTREE. If you don't see the email, kindly check your spam folder."
        );
      }
    } catch (err: any) {
      setMessage(null);
      if (
        err.response?.data?.message ===
          "Error while making request: getaddrinfo ENOTFOUND identitytoolkit.googleapis.com. Error code: ENOTFOUND" ||
        err.response?.data?.message ===
          'Credential implementation provided to initializeApp() via the "credential" property failed to fetch a valid Google OAuth2 access token with the following error: "Error fetching access token: Error while making request: getaddrinfo ENOTFOUND accounts.google.com. Error code: ENOTFOUND"'
      ) {
        setError("Please check your internet connection and try again.");
      } else {
        setError(
          err.response?.data?.message ||
            err.message ||
            "An error occurred. Please try again."
        );
      }
    } finally {
      setLoading(false);
    }
  };

  // Get field status class for form validation UI
  const getFieldStatusClass = (fieldName: keyof FormErrors) => {
    if (!formData[fieldName as keyof RegisterForm]) return "";
    return fieldErrors[fieldName] ? "is-invalid" : "is-valid";
  };

  return (
    <div className="login-section">
      <div className="row">
        <div className="col-md-8 col-sm-12 register-background d-none d-sm-block"></div>
        <div className="col-md-4 col-sm-12 p-5">
          <div className="text-center">
            <a href="/">
              <img
                src={logo}
                className="img-fluid d-block mx-auto mb-4"
                alt="iGiTREE logo"
                style={{ width: "15%" }}
              />
            </a>
          </div>
          <h5 className="text-center text-primary">Sign Up To iGiTREE</h5>
          <form
            onSubmit={handleSubmit}
            className="main-input"
            autoComplete="off">
            <div className="row">
              {/* First Name */}
              <div className="col-sm-12 mt-2">
                <div className="input-group">
                  <span className="input-group-text">
                    <i className="fa-sharp fa-light fa-address-card"></i>
                  </span>
                  <div className="form-floating">
                    <input
                      type="text"
                      className={`form-control ${getFieldStatusClass(
                        "firstName"
                      )}`}
                      placeholder="First Name"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                      required
                    />
                    <label>First Name</label>
                  </div>
                </div>
                {fieldErrors.firstName && (
                  <div className="text-danger small mt-1">
                    {fieldErrors.firstName}
                  </div>
                )}
              </div>

              {/* Last Name */}
              <div className="col-sm-12 mt-2">
                <div className="input-group">
                  <span className="input-group-text">
                    <i className="fa-sharp fa-light fa-user-tie"></i>
                  </span>
                  <div className="form-floating">
                    <input
                      type="text"
                      className={`form-control ${getFieldStatusClass(
                        "lastName"
                      )}`}
                      placeholder="Last Name"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                      required
                    />
                    <label>Last Name</label>
                  </div>
                </div>
                {fieldErrors.lastName && (
                  <div className="text-danger small mt-1">
                    {fieldErrors.lastName}
                  </div>
                )}
              </div>

              {/* Gender */}
              <div className="col-sm-12 mt-2">
                <div className="input-group">
                  <span className="input-group-text">
                    <i className="fa-sharp fa-light fa-venus-mars"></i>
                  </span>
                  <div className="form-floating">
                    <select
                      className={`form-control ${getFieldStatusClass(
                        "gender"
                      )}`}
                      name="gender"
                      value={formData.gender}
                      onChange={handleChange}
                      required>
                      <option value="">Select Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                    <label>Gender</label>
                  </div>
                </div>
              </div>

              {/* Phone Number */}
              <div className="col-sm-12 mt-2">
                <div className="input-group">
                  <span className="input-group-text">
                    <i className="fa-sharp fa-light fa-phone"></i>
                  </span>
                  <div className="form-floating">
                    <input
                      type="text"
                      className={`form-control ${getFieldStatusClass(
                        "phoneNumber"
                      )}`}
                      placeholder="Phone Number"
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={handleChange}
                      required
                    />
                    <label>Phone Number</label>
                  </div>
                </div>
                {fieldErrors.phoneNumber && (
                  <div className="text-danger small mt-1">
                    {fieldErrors.phoneNumber}
                  </div>
                )}
              </div>

              {/* Hidden username field to trick browser */}
              <input type="text" name="username" style={{ display: "none" }} />

              {/* Email */}
              <div className="col-sm-12 mt-2">
                <div className="input-group">
                  <span className="input-group-text">
                    <i className="fa-sharp fa-light fa-envelope"></i>
                  </span>
                  <div className="form-floating">
                    <input
                      type="email"
                      className={`form-control ${getFieldStatusClass("email")}`}
                      placeholder="Email Address"
                      name={inputNames.email}
                      value={formData.email}
                      onChange={handleChange}
                      required
                      autoComplete="off"
                      onFocus={preventAutofill}
                    />
                    <label>Email Address</label>
                  </div>
                </div>
                {fieldErrors.email && (
                  <div className="text-danger small mt-1">
                    {fieldErrors.email}
                  </div>
                )}
              </div>

              {/* Password */}
              <div className="col-sm-12 mt-2">
                <div className="input-group">
                  <span className="input-group-text">
                    <i className="fa-sharp fa-light fa-lock"></i>
                  </span>
                  <div className="form-floating position-relative">
                    <input
                      type={showPassword ? "text" : "password"}
                      className={`form-control ${getFieldStatusClass(
                        "password"
                      )}`}
                      placeholder="Password"
                      name={inputNames.password}
                      value={formData.password}
                      onChange={handleChange}
                      required
                      autoComplete="new-password"
                      onFocus={preventAutofill}
                      data-lpignore="true"
                      autoCapitalize="off"
                      spellCheck="false"
                    />
                    <label>Password</label>
                    <i
                      className={`fa-sharp fa-light ${
                        showPassword ? "fa-eye-slash" : "fa-eye"
                      } position-absolute`}
                      onClick={() => setShowPassword(!showPassword)}
                      style={{
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                        zIndex: 5
                      }}></i>
                  </div>
                </div>
                {fieldErrors.password && (
                  <div className="text-danger small mt-1">
                    {fieldErrors.password}
                  </div>
                )}
                {formData.password && !fieldErrors.password && (
                  <div className="text-success small mt-1">
                    <i className="fa-solid fa-check-circle me-1"></i>
                    Password meets requirements
                  </div>
                )}
              </div>

              {/* Confirm Password */}
              <div className="col-sm-12 mt-2">
                <div className="input-group">
                  <span className="input-group-text">
                    <i className="fa-sharp fa-light fa-lock"></i>
                  </span>
                  <div className="form-floating position-relative">
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      className={`form-control ${getFieldStatusClass(
                        "confirmPassword"
                      )}`}
                      placeholder="Confirm Password"
                      name={inputNames.confirmPassword}
                      value={formData.confirmPassword}
                      onChange={handleChange}
                      required
                      autoComplete="new-password"
                      onFocus={preventAutofill}
                      data-lpignore="true"
                      autoCapitalize="off"
                      spellCheck="false"
                    />
                    <label>Confirm Password</label>
                    <i
                      className={`fa-sharp fa-light ${
                        showConfirmPassword ? "fa-eye-slash" : "fa-eye"
                      } position-absolute`}
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      style={{
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                        zIndex: 5
                      }}></i>
                  </div>
                </div>
                {fieldErrors.confirmPassword && (
                  <div className="text-danger small mt-1">
                    {fieldErrors.confirmPassword}
                  </div>
                )}
              </div>

              {/* Date of Birth */}
              <div className="col-sm-12 mt-2">
                <div className="input-group">
                  <span className="input-group-text">
                    <i className="fa-sharp fa-light fa-calendar"></i>
                  </span>
                  <div className="form-floating">
                    <input
                      type="date"
                      className="form-control"
                      placeholder="Date of Birth"
                      name="dateOfBirth"
                      value={formData.dateOfBirth}
                      onChange={handleChange}
                      required
                    />
                    <label>Date of Birth</label>
                  </div>
                </div>
              </div>

              {/* City */}
              <div className="col-sm-12 mt-2">
                <div className="input-group">
                  <span className="input-group-text">
                    <i className="fa-sharp fa-light fa-city"></i>
                  </span>
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="City"
                      name="city"
                      value={formData.city}
                      onChange={handleChange}
                      required
                    />
                    <label>City</label>
                  </div>
                </div>
              </div>
            </div>

            {/* Password Strength Indicator */}
            {formData.password && (
              <div className="mt-2">
                <div className="d-flex align-items-center">
                  <div className="me-2">Password Strength:</div>
                  <div
                    className="progress flex-grow-1"
                    style={{ height: "8px" }}>
                    <div
                      className={`progress-bar ${
                        isPasswordStrong(formData.password)
                          ? "bg-success"
                          : "bg-danger"
                      }`}
                      role="progressbar"
                      style={{
                        width: `${Math.min(
                          (formData.password.length / 8) * 100,
                          isPasswordStrong(formData.password) ? 100 : 70
                        )}%`
                      }}></div>
                  </div>
                </div>
                <ul className="list-unstyled small mt-2">
                  <li
                    className={`${
                      /[A-Za-z]/.test(formData.password)
                        ? "text-success"
                        : "text-muted"
                    }`}>
                    <i
                      className={`fa-solid ${
                        /[A-Za-z]/.test(formData.password)
                          ? "fa-check-circle"
                          : "fa-circle"
                      } me-1`}></i>
                    Contains letters
                  </li>
                  <li
                    className={`${
                      /\d/.test(formData.password)
                        ? "text-success"
                        : "text-muted"
                    }`}>
                    <i
                      className={`fa-solid ${
                        /\d/.test(formData.password)
                          ? "fa-check-circle"
                          : "fa-circle"
                      } me-1`}></i>
                    Contains numbers
                  </li>
                  <li
                    className={`${
                      /[!@#$%^&*]/.test(formData.password)
                        ? "text-success"
                        : "text-muted"
                    }`}>
                    <i
                      className={`fa-solid ${
                        /[!@#$%^&*]/.test(formData.password)
                          ? "fa-check-circle"
                          : "fa-circle"
                      } me-1`}></i>
                    Contains special characters (!@#$%^&*)
                  </li>
                  <li
                    className={`${
                      formData.password.length >= 8
                        ? "text-success"
                        : "text-muted"
                    }`}>
                    <i
                      className={`fa-solid ${
                        formData.password.length >= 8
                          ? "fa-check-circle"
                          : "fa-circle"
                      } me-1`}></i>
                    At least 8 characters long
                  </li>
                </ul>
              </div>
            )}

            <div className="row">
              <div className="col-sm-12 mt-2">
                <div className="d-grid gap-2">
                  <button
                    className="btn btn-primary btn-lg btn-flat"
                    type="submit"
                    disabled={!isFormValid || loading}>
                    {loading ? "Loading ..." : "Sign Up"}
                  </button>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                {message && (
                  <div className="alert alert-success">{message}</div>
                )}
                {error && (
                  <div className="bg-danger color-palette p-2">{error}</div>
                )}
              </div>
            </div>
          </form>

          {/* Terms and Existing Account Links */}
          <div className="row mt-3">
            <div className="col-md-12 text-center g-font-size-10--xs">
              By clicking the "Sign up" button, you are creating an account and
              agree to the <a href="/terms-of-use">Terms of Use</a> and{" "}
              <a href="/privacy-policy">Privacy Policy</a>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-12 text-center">
              Already have an account?{" "}
              <a href="/login" className="text-strong">
                Log In
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
