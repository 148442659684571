import React, { useEffect } from "react";

// Declare global IremboPay type for TypeScript
declare global {
  interface Window {
    IremboPay: {
      initiate: (options: {
        publicKey: string;
        invoiceNumber: number;
        locale: any;
        callback: (err: any, resp: any) => void;
      }) => void;
      locale: {
        EN: string;
        FR: string;
        RW: string;
      };
      closeModal?: () => void;
    };
  }
}

const IremboPaymentButton: React.FC = () => {
  useEffect(() => {
    // Load the IremboPay script only once
    const script = document.createElement("script");
    script.src = "https://dashboard.sandbox.irembopay.com/assets/payment/inline.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const makePayment = () => {
    if (window.IremboPay) {
      window.IremboPay.initiate({
        publicKey: "pk_live_db8babc680ff46bd9d667f6c88bc30bd",
        invoiceNumber: 880321222465,
        locale: window.IremboPay.locale.EN,
        callback: (err: unknown, resp: unknown) => {
          if (!err) {
            console.log("Payment Success", resp);
            // window.IremboPay.closeModal?.(); // Optional close
          } else {
            console.error("Payment Error", err);
          }
        }
      });
    } else {
      console.error("IremboPay script not loaded yet.");
    }
  };


  return (
    <button
      onClick={makePayment}
      className="btn btn-success mt-5"
    >
      Upgrade to iGiTREE Premium
    </button>
  );
};

export default IremboPaymentButton;
