import React, { useEffect, useState } from 'react';
import API from '../../api/api';

interface Subscription {
    userId: string;
    status: string;
    paymentFrequency: string;
    paymentAmount: number;  
    lastPaymentDate: string;
    startDate: string;
    endDate: string;
    missedPaymentPeriods: number;
    overDueAmount: number;
    totalAmountSpent: number;
    
}

const SubscriptionPage: React.FC = () => {
    const [subscription, setSubscription] = useState<Subscription | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchSubscription = async () => {
            try {
                const token = JSON.parse(localStorage.getItem("token") || '""');
                const response = await API.get(`/subscription`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (response.status === 200) {
                    setSubscription(response.data);
                } else {
                    setError("Failed to load subscription data.");
                }
            } catch (err) {
                console.error("Error fetching subscription data:", err);
                setError("An error occurred while fetching subscription data.");
            } finally {
                setLoading(false);
            }
        };

        fetchSubscription();
    }, []); // Empty dependency array to run only once on mount

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    if (!subscription) {
        return <div>No subscription data available.</div>;
    }
console.log(subscription);
    return (
        <div className="container mt-4">
            <div className="card">
                <div className="card-header">
                    <h2 className="mb-0">Subscription Details</h2>
                </div>
                <div className="card-body">
                    <div className="list-group">
                        <p className="list-group-item"><strong>Status:</strong> {subscription.status}</p>
                        <p className="list-group-item"><strong>Frequency:</strong> {subscription.paymentFrequency}</p>
                        <p className="list-group-item"><strong>Amount:</strong> RWF {subscription.paymentAmount.toFixed(2)}</p>
                        <p className="list-group-item"><strong>Start Date:</strong> {new Date(subscription.startDate).toLocaleDateString()}</p>
                        <p className="list-group-item"><strong>End Date:</strong> {new Date(subscription.endDate).toLocaleDateString()}</p>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubscriptionPage;