import { createContext, useContext, useEffect, useMemo, useState, ReactNode } from "react";
import { getIdToken, onAuthStateChanged, User as FirebaseUser } from "firebase/auth";
import { auth } from "../firebase.config";
import API from "../api/api";

interface UserContextProps {
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  loading: boolean;
}

interface User {
  id: string;
  firstName: string;
  email: string;
  phoneNumber: string;
  lastName: string;
  imageUrl: string;
  subscriptionStatus: string;
}

interface GetUserArgs {
  token?: string;
  user?: FirebaseUser;
}

export const UserContext = createContext<UserContextProps | undefined>(undefined);

export const getUser = async (args: GetUserArgs): Promise<User | null> => {
  const token = args.token ? args.token : await getIdToken(args.user!);

  const cachedUser = localStorage.getItem("user");
  if (cachedUser) {
    try {
      return JSON.parse(cachedUser);
    } catch (error) {
      console.error("Error parsing cached user data:", error);
      return null;
    }
  }

  try {
    const response = await API.get("/auth/user", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      return response.data.userData;
    }

    await auth.signOut();
    throw new Error("There was an error getting your profile");
  } catch (error) {
    console.error("Error fetching user data:", error);
    return null;
  }
};

interface UserProviderProps {
  children: ReactNode;
}

export const UserProvider = ({ children }: UserProviderProps) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (authUser) => {
      if (authUser) {
        const userData = await getUser({ user: authUser });
        setUser(userData || null); // Set user or null if fetching failed
      } else {
        setUser(null);
        localStorage.removeItem("user");
      }
      setLoading(false); // Always set loading to false
    });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
    }
  }, [user]);

  const contextValue = useMemo(() => ({ user, setUser, loading }), [user, loading]);

  return (
    <UserContext.Provider value={contextValue}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = (): UserContextProps => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};
