import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import API from "../../api/api";


declare global {
  interface Window {
    IremboPay: {
      initiate: (options: {
        publicKey: string;
        invoiceNumber: number;
        locale: any;
        callback: (err: any, resp: any) => void;
      }) => void;
      locale: {
        EN: string;
        FR: string;
        RW: string;
      };
      closeModal?: () => void;
    };
  }
}

interface CustomerInfo {
  name: string;
  email: string;
  phoneNumber: string;
}

const GenerateInvoice: React.FC = () => {
  const [billingOption, setBillingOption] = useState<"monthly" | "yearly">("monthly");
  const [quantity, setQuantity] = useState<number>(1);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const unitPrice = billingOption === "monthly" ? 400 : 4000;
  const totalPrice = unitPrice * quantity;

  useEffect(() => {
    // Load the IremboPay script only once
    const script = document.createElement("script");
    script.src = "https://dashboard.sandbox.irembopay.com/assets/payment/inline.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const makePayment = (invoiceNumber: number) => {
    if (window.IremboPay) {
      window.IremboPay.initiate({
        publicKey: `${process.env.REACT_APP_PUBLIC_KEY}`,
        invoiceNumber,
        locale: window.IremboPay.locale.EN,
        callback:async (err: any, resp: any) => {
          console.log("Full Payment Response:", resp); 
          if (!err) {
            const response = resp as { message: string }; 

            if (response.message === "Payment successful") {
              try {
                await API.post("/subscription", {
                  paymentFrequency: billingOption,
                  paymentAmount: totalPrice,
                  paymentMethod: "N/A",
                  invoiceNumber,
                });
  
              } catch (error) {
                console.error("Error sending subscription request:", error);
              }
            }
            localStorage.removeItem("user");
            navigate("/dashboard");
            window.IremboPay.closeModal?.();
          } else {
            console.error("Payment Error", err);
          }
        }
      });
    } else {
      console.error("IremboPay script not loaded yet.");
    }
  };


  const handlePay = async () => {
    setLoading(true);
    setMessage("");

    const customer: CustomerInfo = {
      name: "Jixle Manzi",
      email: "user@email.com",
      phoneNumber: "0780000001",
    };

    try {
      const response = await API.post("/subscription/create_invoice", {
        billingOption,
        quantity,
        customer,
        totalPrice,
        unitPrice
      });
      const { invoiceNumber, success } = response.data;
      console.log("Invoice:", response.data);
      if (success) {
        makePayment(invoiceNumber);
      }
      setMessage("✅ Invoice created successfully!");
    } catch (error: any) {
      const errorMsg =
        error.response?.data?.error ||
        error.message ||
        "Invoice creation failed";
      setMessage("❌ " + errorMsg);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ padding: "20px", maxWidth: "400px", margin: "0 auto", border: "1px solid #ccc", borderRadius: "8px" }}>
      <h2 style={{ marginBottom: "15px" }}>Choose Subscription</h2>

      {/* Billing Option Dropdown */}
      <label style={{ display: "block", marginBottom: "10px" }}>
        Billing Option:
        <select
          value={billingOption}
          onChange={(e) => {
            setBillingOption(e.target.value as "monthly" | "yearly");
            setQuantity(1); // reset duration
          }}
          style={{ marginLeft: "10px", padding: "5px", width: "60%" }}
        >
          <option value="monthly">Monthly - 400 RWF</option>
          <option value="yearly">Yearly - 4000 RWF</option>
        </select>
      </label>

      {/* Quantity Selector */}
      <label style={{ display: "block", marginBottom: "10px" }}>
        Duration ({billingOption === "monthly" ? "Months" : "Years"}):
        <input
          type="number"
          min={1}
          value={quantity}
          onChange={(e) => setQuantity(Math.max(1, Number(e.target.value)))}
          style={{ marginLeft: "10px", padding: "5px", width: "60px" }}
        />
      </label>

      {/* Total Price Display */}
      <p>Total: <strong>{totalPrice} RWF</strong></p>

      {/* Pay Button */}
      <button
        onClick={handlePay}
        disabled={loading}
        style={{
          backgroundColor: loading ? "#ccc" : "#28a745",
          color: "#fff",
          padding: "10px 20px",
          borderRadius: "6px",
          border: "none",
          cursor: loading ? "not-allowed" : "pointer",
        }}
      >
        {loading ? "Processing..." : `Pay ${totalPrice} RWF`}
      </button>

      {/* Message */}
      {message && <p style={{ marginTop: "15px", color: message.startsWith("✅") ? "green" : "red" }}>{message}</p>}
    </div>
  );
};

export default GenerateInvoice;

