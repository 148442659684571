import React, { useState } from 'react'
import NavBar from '../Welcome/NavBar';
import Footer from '../Welcome/Footer';
import GenerateInvoice from '../payment/GenerateInvoice';

const Upgrade = () => {
  const [isButton, setIsButton] = useState<boolean>(true);

  return (
    <div>
        <NavBar />
        
        <section className="bg-light mt-5">
      <div className="text-center bg-white p-4 rounded">
        <h1 className="text-primary">🌳 Grow Your Family Legacy</h1>
        <p className="text-muted">
          Unlock unlimited possibilities, exclusive features, and seamless collaboration with your family.
        </p>
        <p className="text-muted">Ready to build and preserve your family's history? Upgrade now!</p>
        {isButton && 
        <a href="#" className="btn btn-success mt-5" onClick={(e) => {
          e.preventDefault();
          setIsButton(false);
        }}>Upgrade to iGiTREE Premium</a>
}
{!isButton && 
        <GenerateInvoice   />
}

        <h2 className="text-secondary mb-5 mt-5">Membership Benefits</h2>
      <div className="row">
        <div className="col-md-4">
          <div className="card shadow-sm p-3">
            <h5 className="card-title">Unlimited Opportunities</h5>
            <p className="card-text">Add as many family members as you like, with no limits. Stay connected and maximise your family tree’s potential by receiving instant notifications whenever new members are added. The more connections, the better!</p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card shadow-sm p-3">
            <h5 className="card-title">Exclusive Access for students</h5>
            <p className="card-text">Join our private discussion forums and connect with family history enthusiasts.</p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card shadow-sm p-3">
            <h5 className="card-title">Monthly Expert Sessions</h5>
            <p className="card-text">Gain insights from genealogy experts to help you uncover your family heritage.</p>
          </div>
        </div>
       
        
      </div>
      <div className="text-center mt-4">
        <p className="text-muted mb-5 mt-5">Upgrade now for just <strong>RWF 5000 per year</strong> and unlock the full potential of your family's story.</p>
        {isButton && 
        <a href="#" className="btn btn-success mt-5" onClick={(e) => {
          e.preventDefault();
          setIsButton(false);
        }}>Upgrade to iGiTREE Premium</a>
}
{!isButton && 
        <GenerateInvoice   />
}
      </div>
    
    </div>
        </section>
        <Footer />
    </div>
  )
}

export default Upgrade;