import { getMessaging, getToken } from "firebase/messaging";
import { app } from "../firebase.config";

export const requestNotificationPermission = async () => {
  try {
    const messaging = getMessaging(app);

    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      const fcmToken = await getToken(messaging, {
        vapidKey: process.env.VITE_APP_FIREBASE_VAPID_KEY,
      });
      console.log("FCM Token:", fcmToken);
      // Send this token to backend if needed
    } else {
      console.warn("Notification permission denied");
    }
  } catch (err) {
    console.error("FCM token error", err);
  }
};
