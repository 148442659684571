import axios from "axios";
import { auth } from "../firebase.config";

// Create an Axios instance
const API = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});

API.interceptors.request.use(async (config) => {
  const user = auth.currentUser;
  if (user) {
    const token = await user.getIdToken();
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default API;
