import React, { useEffect, useState } from 'react';
import boardImageone from '../../assets/img/profile/vail.png'; // Fallback image
import API from '../../api/api';
import sample from "./default.png";
import { useUser } from '../../context/UserContext';

interface FamilyTree {
  id: string;
  createdAt: string;
  name: string;
  membersCount: number;
  board_image: string; // URL for image or fallback image if not provided
  description: string;
  members: string[];
  imageUrl: string;
  dateCreated: {
    _seconds: number;
    _nanoseconds: number;
  };
}

interface DashboardMainProps {
  onMembersCountUpdate: (count: number) => void;
}

const DashboardMain: React.FC<DashboardMainProps> = ({ onMembersCountUpdate }) => {
  const [familyTrees, setFamilyTrees] = useState<FamilyTree[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [deleting, setDeleting] = useState<string | null>(null);
  const { user } = useUser();



  useEffect(() => {
    const fetchFamilyTrees = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("token") || '""');
        const response = await API.get("/family_tree", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          const trees = response?.data?.familyTrees || [];
          setFamilyTrees(trees);
          if (trees.length > 0) {
            onMembersCountUpdate(trees[0].membersCount);
          } else {
            onMembersCountUpdate(0);
          }
        } else {
          setError("Failed to load family trees.");
        }
      } catch (err) {
        console.error("Error fetching family tree data:", err);
        setError("An error occurred while fetching family tree data.");
      } finally {
        setLoading(false);
      }
    };

    fetchFamilyTrees();
  }, [onMembersCountUpdate]);
  const handleDelete = async (treeId: string) => {
    if (!window.confirm("Are you sure you want to delete this family tree?")) {
      return;
    }

    setDeleting(treeId);
    setError(null);
    try {
      const token = JSON.parse(localStorage.getItem("token") || '""');
      const response = await API.delete(`/family_tree/${treeId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setFamilyTrees((prevTrees) => prevTrees.filter(tree => tree.id !== treeId));
        alert("Family tree successfully deleted.");
      } else {
        setError("Failed to delete the family tree.");
      }
    } catch (err) {
      console.error("Error deleting family tree:", err);
      setError("An error occurred while deleting the family tree.");
    } finally {
      setDeleting(null);
    }
  };



  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="row">
      <div className="col-md-12 col-sm-12">
        <div className="row align-items-center mb-4">
          <div className="col-md-6 col-sm-6 align-items-center">
            <h4 className="g-font-size-18--xs">
              Family Trees
              <span id="treeCount" className="badge badge-sm text-bg-secondary">{familyTrees.length}</span>
            </h4>
          </div>
          {familyTrees.length === 0 &&
            <div className="col-md-6 col-sm-6">
              <button
                type="button"
                className="btn btn-primary btn-flat mt-3 float-end"
                onClick={() => window.location.href = '/newtree'}>
                <i className="fa-sharp fa-light fa-plus"></i>
                New Family Tree
              </button>
            </div>
          }
        </div>

        {/* Container to display family tree cards */}
        <div id="familyTreeContainer">
          {familyTrees.length > 0 && familyTrees.map(tree => (
            <div className="card mb-3 shadow p-1" key={tree.id}>
              <div className="row g-0">
                <div className="col-md-2 col-sm-2 align-items-center">
                  <a href="#" data-bs-toggle="modal" data-bs-target={`#editTreeImage${tree.id}`}>
                    <img
                      src={tree.imageUrl || sample} // Use fetched image or fallback
                      className="img-fluid rounded-start"
                      alt={tree.name}
                    />
                  </a>
                </div>
                <div className="col-md-10 col-sm-10 ps-3">
                  <div className="card-body">
                    <h5 className="card-title">
                      <a href={`/tree/${tree.id}`}>
                        <span className="text-primary g-font-size-16--xs">{tree.name}</span>
                      </a>
                    </h5>
                    <p className="card-text">{tree?.description}</p>
                    <hr className="dashed" />
                    <div className="row align-items-center">
                      <div className="col-md-3 col-sm-3">
                        <i className="fa-sharp fa-light fa-calendar"></i>
                        <small className="text-body-secondary m-1">Created {new Date(tree.dateCreated._seconds * 1000).toLocaleDateString()}</small>
                      </div>
                      <div className="col-md-3 col-sm-3">
                        <i className="fa-sharp fa-light fa-users"></i>
                        <small className="text-body-secondary m-2">Members {tree.membersCount}</small>
                      </div>
                      {user?.subscriptionStatus === "active" &&
                        <div className="col-md-3 col-sm-3">
                          <button type="button" className="btn btn-success btn-sm btn-flat" onClick={() => handleDelete(tree.id)}>{deleting ? "Deleting ..." : "Delete"}</button>
                        </div>
                      }
                      {user?.subscriptionStatus !== "active" &&
                        <div className="col-md-3 col-sm-3">
                          <a href={`upgrade`} className="float-end">
                            <button type="button" className="btn btn-success btn-sm btn-flat">pay to View</button>
                          </a>
                        </div>
                      }

                      {user?.subscriptionStatus === "active" && <div className="col-md-3 col-sm-3">
                        <a href={`/tree/${tree.id}`} className="float-end">
                          <button type="button" className="btn btn-success btn-sm btn-flat">View</button>
                        </a>
                      </div>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {familyTrees.length === 0 &&
            <p>You haven't created any family trees yet.</p>
          }
        </div>
      </div>
    </div>
  );
};

export default DashboardMain;
