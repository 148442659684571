import { useEffect } from "react";
import { requestNotificationPermission } from "../../helper/firebaseNotificationInit";

const FirebaseNotificationSetup = () => {
  useEffect(() => {
    requestNotificationPermission();
  }, []);

  return null;
};

export default FirebaseNotificationSetup;