import React, { createContext, useContext, useState, useEffect } from 'react';
import { getMessaging, onMessage } from 'firebase/messaging';
import { app } from '../firebase.config';

interface NotificationContextType {
  hasNotification: boolean;
  setHasNotification: (value: boolean) => void;
}

const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

export const NotificationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [hasNotification, setHasNotification] = useState(false);


  return (
    <NotificationContext.Provider value={{ hasNotification, setHasNotification }}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (undefined === context) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }
  return context;
};