import React, { useEffect, useState } from 'react';
import API from '../../api/api'; // Adjust path to your API helper
import { useUser } from '../../context/UserContext';

interface Notification {
  id: string;
  title: string;
  body: string;
  click_action: string;
  type: string;
  boardId: string;
  sender: string;
  timestamp: {
    _seconds: number;
    _nanoseconds: number;
  };
}

const Notifications: React.FC = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { user } = useUser();
  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await API.get('/notifications'); // Update endpoint if needed
        const data = response.data;

        if (data?.data) {
          setNotifications(data.data);
        } else {
          setNotifications([]);
        }
      } catch (err: any) {
        setError(err?.response?.data?.message || 'Failed to load notifications');
      } finally {
        setLoading(false);
      }
    };

    fetchNotifications();
  }, []);

  const timeAgo = (seconds: number) => {
    const date = new Date(seconds * 1000);
    const now = new Date();
    const diff = Math.floor((now.getTime() - date.getTime()) / 1000);

    if (diff < 60) return `${diff} second${diff !== 1 ? 's' : ''} ago`;
    if (diff < 3600) return `${Math.floor(diff / 60)} minute${Math.floor(diff / 60) !== 1 ? 's' : ''} ago`;
    if (diff < 86400) return `${Math.floor(diff / 3600)} hour${Math.floor(diff / 3600) !== 1 ? 's' : ''} ago`;
    return `${Math.floor(diff / 86400)} day${Math.floor(diff / 86400) !== 1 ? 's' : ''} ago`;
  };

  const handleClearAll = async () => {
    try {
      if (!user) {
        throw new Error('User not found');
      }

      const response = await API.delete(`/notifications/${user.id}`);

      if (response.status === 200) {
        setNotifications([]);
      } else {
        console.error("Failed to clear notifications");
      }
    } catch (error) {
      console.error("Error clearing notifications:", error);
    }
  };

  return (
    <div className="container my-4">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h4 className="mb-0">Notifications</h4>
        {notifications.length > 0 && (
          <button className="btn btn-danger btn-sm" onClick={handleClearAll}>
            Clear All
          </button>
        )}
      </div>

      {loading ? (
        <div className="alert alert-info">Loading notifications...</div>
      ) : error ? (
        <div className="alert alert-danger">
          <strong>Error:</strong> {error}
        </div>
      ) : notifications.length === 0 ? (
        <div className="alert alert-warning">No notifications available.</div>
      ) : (
        <div className="list-group">
          {notifications.map((notif) => (
            <div key={notif.id} className="list-group-item list-group-item-action mb-2 shadow-sm rounded">
              <div className="d-flex w-100 justify-content-between">
                <h5 className="mb-2">{notif.title}</h5>
                <small>{timeAgo(notif.timestamp._seconds)}</small>
              </div>
              <p className="mb-1">{notif.body}</p>
              {/* <small className="text-muted">
                <strong>Type:</strong> {notif.type} | <strong>Sender:</strong> {notif.sender} | <strong>Board ID:</strong> {notif.boardId}
              </small> */}
              {/* <div className="mt-2">
                <button className="btn btn-outline-primary btn-sm">
                  {notif.click_action}
                </button>
              </div> */}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Notifications;
