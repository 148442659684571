import React, { useState, useEffect } from "react";
import logo from '../../assets/img/logo.png';
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth, messaging } from "../../firebase.config";
import { Error } from "../Form";
import { useNavigate } from "react-router-dom";
import API from "../../api/api";
import { onAuthStateChanged } from "firebase/auth";
import { getToken } from "firebase/messaging";

interface Messages {
  message?: string
}

interface CustomUser {
  uid: string;
  email: string | null;
  emailVerified: boolean;
  user_id: string; 
}


const SignIn: React.FC = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Messages>({});
  const [isAuthChecked, setIsAuthChecked] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthChecked(true);
      if (user) {
        if (user.emailVerified) {
          navigate("/dashboard");
        } else {
           setError({
             message:
               "Your account is not verified. Please check your email for verification instructions."
           });
            auth.signOut();
        }
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  if (!isAuthChecked) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <div className="loader">Loading...</div>
      </div>
    );
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError({});
    const { email, password } = formData;
    
    try {
      const response = await API.post('/auth/check-user', { email });
    
      if (!response.data.exists) {
        setError({
          message: "The account does not exist, Kindly register",
        });
        setLoading(false);
        return;
      }
      
      const { user } = await signInWithEmailAndPassword(auth, email, password);
      
      if (user) {
        if (user?.emailVerified === false) {
          setError({
            message: "Your account is not verified. Please check your email for verification instructions."
          });
          setLoading(false);
          return;
        }
        const fcmToken = await getToken(messaging, {
          vapidKey: process.env.VITE_APP_FIREBASE_VAPID_KEY,
        });

        console.log("This is the token bro", fcmToken);
        if (fcmToken) {
          const lastFcmToken = localStorage.getItem("lastFcmToken");
          if (fcmToken !== lastFcmToken) {
            console.log(user);
            await API.post(`/notifications/save-fcm-token/${user.uid}`, { fcmToken });
            localStorage.setItem("lastFcmToken", fcmToken);
          }
        } else {
          console.warn("No FCM token received.");
        }
        localStorage.setItem("token", JSON.stringify(await user.getIdToken()));
      } else {
        setLoading(false);
        setError({
          message: "Login failed. Please try again.",
        });
      }
    } catch (error: any) {
      console.error("Sign in error:", error);
      localStorage.removeItem("token");
      setLoading(false);
      if (error.code === "auth/invalid-credential") {
        setError({
          message: "Your Email or Password is incorrect, Please Try Again or Create new Password",
        });
      } else if (error.code === "auth/user-disabled") {
        setError({
          message:
            "Log in failed: Your account has been disabled. Please contact if you think this was a mistake",
        });
      } else if (error.code === "auth/user-not-found") {
        setError({
          message: "Log in failed: No account exists with this email address",
        });
      } else {
        setError({
          message: `Something went wrong: ${error.code}`,
        });
      }
    }
  };

  return (
    <div className="login-section">
      <div className="row">
        <div className="col-md-8 col-sm-12 login-background d-none d-sm-block">
        </div>
        <div className="col-md-4 col-sm-12 p-5">
          <div className="text-center mb-4">
            <a href="/">
              <img
                src={logo}
                alt="iGiTREE logo"
                className="img-fluid d-block mx-auto"
                style={{ width: "15%" }}
              />
            </a>
          </div>
          <h5 className="text-center">Log in To iGiTREE</h5>

          <form onSubmit={handleSubmit} className="main-input">
            <div className="input-group mt-2">
              <span className="input-group-text">
                <i className="fa-sharp fa-light fa-envelope"></i>
              </span>
              <div className="form-floating">
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Email Address"
                  required
                  autoComplete="off"
                />
                <label>Email Address</label>
              </div>
            </div>

            <div style={{ display: 'none' }}>
              <input type="text" autoComplete="username" style={{ display: 'none' }} />
            </div>

            <div className="input-group">
              <span className="input-group-text">
                <i className="fa-sharp fa-light fa-lock"></i>
              </span>
              <div className="form-floating position-relative">
                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  placeholder="Password"
                  required
                  autoComplete="new-password"
                />
                <label>Password</label>
                <i 
                  className={`fa-sharp fa-light ${showPassword ? 'fa-eye-slash' : 'fa-eye'} position-absolute`}
                  onClick={() => setShowPassword(!showPassword)}
                  style={{
                    right: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    cursor: 'pointer',
                    zIndex: 5
                  }}
                ></i>
              </div>
            </div>

            <div className="d-grid mt-4">
              <button type="submit" className="btn btn-primary" disabled={loading}>
                {loading ? "Logging In..." : "Log In"}
              </button>
            </div>
          </form>
          {error?.message && <Error>{error.message}</Error>}
          <div className="text-center mt-3">
            <a href="/forgot" className="text-strong">
              I forgot my password
            </a>
          </div>
          <div className="text-center mt-2">
            Don't you have an account?{" "}
            <a href="/register" className="text-strong">
              Sign Up
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
