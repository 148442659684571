// NotificationPopup.tsx
import React, { useEffect } from 'react';
import styles from './NotificationPopup.module.css';

interface NotificationProps {
  title: string;
  body: string;
  image: string;
  onClose: () => void;
}

const NotificationPopup: React.FC<NotificationProps> = ({ title, body, image, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 40000); 

    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <div className={styles.popup}>
      <button className={styles.closeButton} onClick={onClose}>×</button>
      <img src={"https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"} alt="Notification" className={styles.image} />
      <div className={styles.content}>
        <h4 className={styles.title}>{title}</h4>
        <p className={styles.message}>{body}</p>
      </div>
    </div>
  );
};

export default NotificationPopup;
